body {
  background: #dcdcdc;
}

iframe#webpack-dev-server-client-overlay{display:none!important}

.main {
  display: grid;
  height: 100vh;
  width: auto;
  animation: loading 0.5s linear;
}

@keyframes loading {
  from {
    scale: 0;
    -webkit-transform: scale(0);
  }

  to {
    scale: 1;
    -webkit-transform: scale(1);
  }
}